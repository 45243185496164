import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="July 2021" subnav="release-notes">
      <div id="July2021" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          July was all about fixing issues and addressing requests from our
          users. We added some functionality to our components to make
          components like the{' '}
          <Link href="/components/forms/text-area/">Textareas</Link> and{' '}
          <Link href="/components/notifications/toast/code?reactnative">
            Toasts
          </Link>{' '}
          more robust and developer-friendly.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.9.1"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v4.9.1"
            />

            <div className={styles.changesList}>
              <Added>
                Grouping option to{' '}
                <Link href="/components/action-list/code?web#actions">
                  Action Lists
                </Link>
                .
              </Added>
              <Added>
                The <code>autoHeight</code> prop to{' '}
                <Link href="/components/forms/text-area/code?web#autoHeight">
                  Textareas
                </Link>
                .
              </Added>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="2.0.0 (Jarvis)"
              versionUrl="https://github.com/hudl/jarvis/releases/tag/v2.0.0"
            />

            <div className={styles.changesList}>
              <Added>
                <code>duration</code> prop to{' '}
                <Link href="/components/notifications/toast/code?reactnative#duration">
                  Toasts
                </Link>
                .
              </Added>
              <Added>
                <Link href="/components/notifications/toast/code?reactnative#Toast%20Messenger">
                  ToastMessenger
                </Link>{' '}
                component.
              </Added>
              <Added>
                <code>flexHeight</code> prop to{' '}
                <Link href="/components/forms/text-area/code?reactnative#flexHeight">
                  Textareas
                </Link>
                .
              </Added>
              <Improved>
                <Link href="/components/notifications/notice/code?reactnative#onDismiss">
                  Notice
                </Link>{' '}
                <code>onDismiss</code> prop is now optional.
              </Improved>
              <Improved>
                <Link href="/components/notifications/notice/code?reactnative#testID">
                  Notice
                </Link>{' '}
                <code>testID</code> and <code>accessibilityLabel</code> props
                now pass to actions within Notice.
              </Improved>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Improved>
                Documentation for{' '}
                <Link href="/resources/code-variables/space/?reactnative">
                  Spaces in React Native
                </Link>
                .
              </Improved>
              <Fixed>Incorrect code snippets for React Native Icons.</Fixed>
              <Fixed>
                Issue with{' '}
                <Link href="/guidelines/layout/division/code/">
                  Division Code page
                </Link>{' '}
                not always rendering.
              </Fixed>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
